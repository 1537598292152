import {createRouter, createWebHistory} from 'vue-router'
import Dashboard from '../views/Dashboard.vue'

const routes = [
    {
        path: '/',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
            title: "Dashboard"
        }
    },
    {
        path: '/login',
        name: 'LogIn',
        component: () => import('../views/Login.vue'),
        meta: {
            title: "Log In"
        }
    },
    {
        path: '/accounts',
        name: 'AccountList',
        component: () => import(/* webpackChunkName: "account" */ '../views/account/AccountList.vue'),
        meta: {
            title: "AWS Accounts"
        }
    },
    {
        path: '/accounts/new',
        name: 'AccountCreate',
        component: () => import(/* webpackChunkName: "account" */ '../views/account/AccountCreate.vue'),
        meta: {
            title: "Create AWS Account"
        }
    },
    {
        path: '/studios',
        name: 'StudioList',
        component: () => import(/* webpackChunkName: "studio" */ '../views/studio/StudioList.vue'),
        props: route => ({ reload: route.query.reload }),
        meta: {
            title: "Studios"
        }
    },
    {
        path: '/studios/new',
        name: 'StudioCreate',
        component: () => import(/* webpackChunkName: "studio" */ '../views/studio/StudioCreate.vue'),
        meta: {
            title: "Create Studio"
        }
    },
    {
        path: '/studios/:studioId',
        name: 'Studio',
        component: () => import(/* webpackChunkName: "studio" */ '../views/studio/Studio.vue'),
        props: true,
        meta: {
            title: "Studio"
        }
    },
    {
        path: '/studios/:studioId/edit',
        name: 'StudioConfigure',
        component: () => import(/* webpackChunkName: "studio" */ '../views/studio/StudioConfigure.vue'),
        props: true,
        meta: {
            title: "Configure Studio"
        }
    },
    {
        path: '/studios/:studioId/bootstrap',
        name: 'StudioBootstrap',
        component: () => import(/* webpackChunkName: "studio" */ '../views/studio/StudioBootstrap.vue'),
        props: true,
        meta: {
            title: "Bootstrap Studio"
        }
    },
    {
        path: '/studios/:studioId/groups/:groupId',
        name: 'StudioGroupEdit',
        component: () => import(/* webpackChunkName: "studio" */ '../views/studio/StudioGroupEdit.vue'),
        props: true,
        meta: {
            title: "Edit Active Directory Group Membership"
        }
    },
    {
        path: '/studios/:studioId/edit',
        name: 'StudioConfigure',
        component: () => import(/* webpackChunkName: "studio" */ '../views/studio/StudioConfigure.vue'),
        props: true,
        meta: {
            title: "Studio Configuration Wizard"
        }
    },
    {
        path: '/studios/:studioId/expert_edit',
        name: 'StudioExpertConfigure',
        component: () => import(/* webpackChunkName: "studio" */ '../views/studio/StudioExpertConfigure.vue'),
        props: true,
        meta: {
            title: "Expert Studio Configuration"
        }
    },
    {
        path: '/studios/:studioId/config/:filename',
        name: 'StudioConfigEditor',
        component: () => import(/* webpackChunkName: "studio" */ '../views/studio/ConfigEditor.vue'),
        props: true,
        meta: {
            title: "Configure Studio"
        }
    },
    {
        path: '/studios/:studioId/queues/:queueId',
        name: 'StudioDeadlineQueue',
        component: () => import(/* webpackChunkName: "studio" */ '../views/deadline/Queue.vue'),
        props: true,
        meta: {
            title: "Deadline Cloud Queue"
        }
    },
    {
        path: '/regional-resources',
        name: 'RegionalResourcesList',
        component: () => import(/* webpackChunkName: "regional" */ '../views/regional/RegionalResourcesList.vue'),
        props: route => ({ departmentId: route.query.departmentId }),
        meta: {
            title: "Regional Resources"
        }
    },
    {
        path: '/regional-resources/new',
        name: 'RegionalResourcesCreate',
        component: () => import(/* webpackChunkName: "regional" */ '../views/regional/RegionalResourcesCreate.vue'),
        meta: {
            title: "Create Regional Resources"
        }
    },
    {
        path: '/regional-resources/:departmentId/:region',
        name: 'RegionalResources',
        component: () => import(/* webpackChunkName: "regional" */ '../views/regional/RegionalResources.vue'),
        props: true,
        meta: {
            title: "Regional Resources"
        }
    },
    {
        path: '/regional-resources/:departmentId/:region/edit',
        name: 'RegionalResourcesConfigure',
        component: () => import(/* webpackChunkName: "regional" */ '../views/regional/RegionalResourcesConfigure.vue'),
        props: true,
        meta: {
            title: "Configure Regional Resources"
        }
    },
    {
        path: '/regional-resources/:departmentId/:region/bootstrap',
        name: 'RegionalResourcesBootstrap',
        component: () => import(/* webpackChunkName: "regional" */ '../views/regional/RegionalResourcesBootstrap.vue'),
        props: true,
        meta: {
            title: "Bootstrap Regional Resources"
        }
    },
    {
        path: '/storage',
        name: 'StoreList',
        component: () => import(/* webpackChunkName: "storage" */ '../views/store/StoreList.vue'),
        meta: {
            title: "Storage"
        }
    },
    {
        path: '/storage/new',
        name: 'StoreCreate',
        component: () => import(/* webpackChunkName: "storage" */ '../views/store/StoreCreate.vue'),
        props: route => ({ studioId: route.query.studioId }),
        meta: {
            title: "Create Store"
        }
    },
    {
        path: '/storage/:studioId/:label',
        name: 'Store',
        component: () => import(/* webpackChunkName: "storage" */ '../views/store/Store.vue'),
        props: true,
        meta: {
            title: "Store"
        }
    },
    {
        path: '/storage/:studioId/:label/automation/new',
        name: 'StoreAutomatorCreate',
        component: () => import(/* webpackChunkName: "storage" */ '../views/store/AutomatorCreate.vue'),
        props: true,
        meta: {
            title: "Create Automation"
        }
    },
    {
        path: '/storage/:studioId/:label/automation/:ruleId',
        name: 'StoreAutomatorEdit',
        component: () => import(/* webpackChunkName: "storage" */ '../views/store/AutomatorCreate.vue'),
        props: true,
        meta: {
            title: "Edit Automation"
        }
    },
    {
        path: '/permissions',
        name: 'PermissionList',
        component: () => import(/* webpackChunkName: "permissions" */ '../views/permission/PermissionList.vue'),
        meta: {
            title: "Permissions"
        }
    },
    {
        path: '/permissions/:type/new',
        name: 'PermissionCreate',
        component: () => import(/* webpackChunkName: "permissions" */ '../views/permission/PermissionEditor.vue'),
        props: true,
        meta: {
            title: "New Permission Set"
        }
    },
    {
        path: '/permissions/:type/:name/edit',
        name: 'PermissionEdit',
        component: () => import(/* webpackChunkName: "permissions" */ '../views/permission/PermissionEditor.vue'),
        props: true,
        meta: {
            title: "Edit Permissions"
        }
    },
        {
        path: '/permissions/:type/:name',
        name: 'PermissionInfo',
        component: () => import(/* webpackChunkName: "permissions" */ '../views/permission/PermissionUserInfo.vue'),
        props: true,
        meta: {
            title: "Permission Info"
        }
    },
    {
        path: '/permissions/:type/:name/members',
        name: 'PermissionGroupMembers',
        component: () => import(/* webpackChunkName: "permissions" */ '../views/permission/PermissionGroupMembers.vue'),
        props: true,
        meta: {
            title: "Edit Members"
        }
    },
    {
        path: '/machine-tokens/new',
        name: 'MachineTokenCreate',
        component: () => import(/* webpackChunkName: "permissions" */ '../views/machine_token/MachineTokenCreate.vue'),
        props: route => ({ studioId: route.query.studioId }),
        meta: {
            title: "New Machine Token"
        }
    },
    {
        path: '/config',
        name: 'ConfigList',
        component: () => import(/* webpackChunkName: "config" */ '../views/config/ConfigList.vue'),
        meta: {
            title: "Configuration"
        }
    },
    {
        path: '/config/new',
        name: 'ConfigCreate',
        component: () => import(/* webpackChunkName: "config" */ '../views/config/ConfigEditor.vue'),
        props: true,
        meta: {
            title: "New Configuration"
        }
    },
    {
        path: '/config/:category/:ckey/edit',
        name: 'ConfigEdit',
        component: () => import(/* webpackChunkName: "config" */ '../views/config/ConfigEditor.vue'),
        props: true,
        meta: {
            title: "Edit Configuration"
        }
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: () => import('../views/NotFound.vue'),
        meta: {
            title: "Not Found"
        }
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    // This goes through the matched routes from last to first, finding the closest route with a title.
    // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
    // `/nested`'s will be chosen.
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

    // If a route with a title was found, set the document (page) title to that value.
    if (nearestWithTitle) {
        let isLocalhost = window.location.href.indexOf('localhost') > 0
        document.title = `${nearestWithTitle.meta.title} | ${isLocalhost ? 'DEV' : ''} Wheelhouse`;
    }

    next()
})

export default router
